<template>
   <div class="modal__content__box">
      <div class="modal__content">
         <div class="item__modal">
            <Typography weigth="weigth" :size="'bigger'">Delete {{ formData.name }} list?</Typography>
         </div>
         <!-- <form action="" @submit.prevent="submitHandler"> -->
         <!-- <div class="input__prising_modal">
               <SWInput placeholder="My new list" v-model="valueInput" />
            </div> -->
         <div class="button_check__modal">
            <SWButton variant="primary" type="submit" size="big" @click="deleteList">Delete</SWButton>
         </div>
         <div class="button_skip__modal">
            <SWButton :isCurrentClass="true" variant="secondary" size="big" @click="$emit('onCancel')">Cancel</SWButton>
         </div>
         <!-- </form> -->
      </div>
   </div>
</template>

<script>
import { SWButton } from 'sw-ui-kit';
import Typography from '../../../components/UI/Typography.vue';
import * as Sentry from '@sentry/vue';

export default {
   inject: ['snackBar', 'isEditMenuOpen'],
   components: {
      Typography,
      SWButton,
   },
   props: {
      formData: {
         type: Object,
         required: false,
      },
   },

   methods: {
      async deleteList() {
         /*          const elemToDelete = this.listsSelecting.find(el => el.name === this.categoryNameToEdit);

         const deletedElementName = elemToDelete ? elemToDelete.name : this.selectedList.name; */

         //  if (elemToDelete || this.selectedList) {
         try {
            await this.$http.delete(`resources/delete/lists/${this.formData?.id}`);
            //  this.$store.dispatch(`advice/${actions.FETCH}`);
            /*    this.$store.commit(
                  `advice/${mutations.UPDATE_SELECTED_CATEGORY}`,
                  this.$store.getters['advice/getCategoriesList']?.[0]?.code || 'All',
               ); */
            this.$emit('onCancel');
            this.isEditMenuOpen = false;

            this.snackBar('List ' + this.formData?.name + ' has been deleted ');
            this.$store.commit('app/UPDATE_CATEGORY_LIST_HAS_CHANGES');
            if (this.formData.from && this.formData.from === 'list') {
               window.location.reload();
            }
         } catch (err) {
            Sentry.captureException(err);
            this.$emit('onCancel');
         }
         // }
      },
      // handleSaveToList() {
      //    console.log(123);
      //    this.openAdviceModal().then(() => {
      //       this.$emit('clear');
      //    });
      // },
   },

   computed: {
      /*     categoryNameToEdit() {
         return this.$store.getters['advice/getCategoryNameToEdit'];
      },
      selectedList() {
         return this.$store.getters['advice/getListsSelecting'].find(
            el => el.name === this.$store.getters['advice/getSelectedCategory'],
            // console.log(this.$store.getters['advice/getSelectedCategory']),
         );
      },
      listsSelecting() {
         return this.$store.getters['advice/getListsSelecting'];
      }, */
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   flex-direction: column;
   color: var(--font-color-black);
}
.modal__content {
   display: flex;
   flex-direction: column;
   width: 340px;
}

.input__prising_modal {
   margin-bottom: 40px;
}
.modal__contentModal {
   max-width: 358px;
}
.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}

.item__modal {
   margin-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}
</style>
