<template>
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_1383_19169)">
         <path
            d="M4 19.6136H8L18.5 9.11358C19.0304 8.58315 19.3284 7.86373 19.3284 7.11358C19.3284 6.36344 19.0304 5.64402 18.5 5.11358C17.9696 4.58315 17.2501 4.28516 16.5 4.28516C15.7499 4.28516 15.0304 4.58315 14.5 5.11358L4 15.6136V19.6136Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path d="M13.7148 6.85938L17.1434 10.2879" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
         <clipPath id="clip0_1383_19169">
            <rect width="24" height="24" fill="white" />
         </clipPath>
      </defs>
   </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
   stroke: var(--default-color-svg);
}
</style>
