<template>
   <div class="modal__content__box">
      <div class="modal__content">
         <div class="item__modal">
            <Typography weigth="weigth" :size="'bigger'">New list</Typography>
         </div>
         <form action="" @submit.prevent="submitHandler">
            <div class="input__prising_modal">
               <SWInput
                  :class="{ invalidDataErrorStyles: showErrorMessage }"
                  placeholder="List name"
                  v-model="valueInput"
                  :errorMessage="'The name field cannot be empty'"
                  :isError="showErrorMessage"
               />
            </div>
            <div class="button_check__modal">
               <SWButton variant="primary" type="submit" size="big">Create</SWButton>
            </div>
            <div class="button_skip__modal">
               <SWButton :isCurrentClass="true" variant="secondary" size="big" @click="$emit('onCancel')"
                  >Cancel</SWButton
               >
            </div>
         </form>
      </div>
   </div>
</template>

<script>
import { SWButton, SWInput } from 'sw-ui-kit';
import Typography from '../../../components/UI/Typography.vue';
export default {
   inject: ['snackBar'],
   components: {
      Typography,
      SWButton,
      SWInput,
   },
   props: {
      formData: {
         type: Object,
         required: false,
      },
   },

   data() {
      return {
         isOpen: false,
         showErrorMessage: false,
         valueInput: '',
      };
   },

   computed: {
      selected() {
         return this.$store.getters['advice/getSelected'];
      },
   },

   methods: {
      async submitHandler() {

         const res = await this.$http.post('resources/create/lists', { name: this.valueInput });


         if (res.status === 201) {
            if (this.formData?.records.length > 0) {

               await this.$http.post(`/resources/list/addAdvice`, {
                  lists: [res.data._id],
                  records: this.formData?.records,
               });

            }
            this.snackBar('A ' + this.valueInput + ' has been created');
            this.$emit('onCancel');
            this.$store.commit('app/UPDATE_CATEGORY_LIST_HAS_CHANGES');
         } else {

            this.showErrorMessage = true;
            const setShowError = setTimeout(() => {
               this.showErrorMessage = false;
               clearTimeout(setShowError);
            }, 2000);
         }
      },
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   flex-direction: column;
   color: var(--font-color-black);
}
.modal__content {
   display: flex;
   flex-direction: column;
   width: 340px;
}

.input__prising_modal {
   margin-bottom: 40px;
}
.modal__contentModal {
   max-width: 358px;
}
.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}

.item__modal {
   margin-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}
.errorMassageText {
   font-size: 12px;
   color: red;
}
.invalidDataErrorStyles .input {
   /* transition: all 0.1s; */
   border: 1px solid !important;
   border-color: #ff1730 !important;
   outline: #ff1730;

   border-radius: 11.5px;
}
</style>
