<template>
   <div>
      <SWSelect
         ref="select"
         @click="removeItem(value)"
         :options="[...builtInCategories, ...[''], ...predefinedLists, ...userLists]"
         @select="setCategory"
         :selected="currentCategoryName"
         @open="handleOpen"
         @close="handleClose"
      >
         <template #underListActions>
            <button class="buttonCreate" @click="createList">
               <div class="content_btnCreate">
                  <div class="img_create">
                     <img src="../../../assets/img/KrestickAddCupon.svg" alt="" />
                  </div>
                  <div class="create_text">Create a new list</div>
               </div>
            </button>
         </template>
      </SWSelect>

      <div class="backdrop" v-if="isBackDropOpen"></div>
   </div>
</template>

<script>
import { SWSelect } from 'sw-ui-kit';

import IconDelete from './IconDelete.vue';
import IconEdit from './IconEdit.vue';
import { FORM_RENAME, FORM_DELETE, FORM_CREATE_LIST } from '../../../components/Layouts/LayoutAplication.vue';
const EXCLUDED_CATEGORIES = ['Habits'];
const PREDEFINED_CATEGORIES = ['View later', 'All'];
//import mutations from '../../../store/mutation-types';
//import log from '../../../logger/logger';

export default {
   inject: ['openModal'],
   components: {
      SWSelect,
   },
   data() {
      return {
         isBackDropOpen: false,
         categories: [],
         defaultCategory: '',


         options: [
            //? not used
            { name: 'Morning', value: 0 },
            { name: 'Awakening', value: 1 },
            { name: 'Day', value: 2 },
            { name: 'Evening', value: 3 },
            { name: 'During sleep', value: 4 },
            { name: 'Bedroom', value: 5 },
            { name: 'Food', value: 6 },
            { name: 'Physical activity', value: 7 },
            { name: 'Breathing practices', value: 8 },
            { name: 'Relaxation exercises', value: 9 },
            { name: 'All', value: 11 },
         ],
      };
   },
   async created() {
      const categories = await this.$http.get('/resources/categories');
      this.categories = categories.data.filter(el => EXCLUDED_CATEGORIES.includes(el.name) === false);
      this.$store.commit(`app/UPDATE_CUSTOM_LISTS`, this.categories.filter(el => el.group === 'list'));
      this.$store.commit(`app/UPDATE_CURRENT_CATEGORY`, { id: this.categories[0]._id, name: this.categories[0].name, group: this.categories[0]?.group, predefined: this.categories[0]?.predefined }); //! crutch. When to switch from habits to advices for a bit in category list habits appears. To avoid this use this crutch
   },

   methods: {
      setCategory(option) {
         this.$store.commit(`app/UPDATE_CURRENT_CATEGORY`, { id: option._id, name: option.name, group: option?.group || null, predefined: option?.predefined || false });
         this.$store.commit('app/TOGGLE_CLOSE_PLAYER');
  //       log(this.$http, { message: `selected a category ${option?.name}`, level: 'info' });
      },

      deleteItem(name, id) {
       //  this.$store.commit('advice/setCategoryNameToEdit', name);
         this.isBackDropOpen = false;
         this.openModal(FORM_DELETE,  { name, id });
      },
      editItem(name, id, group) {
         //this.$store.commit('advice/setCategoryNameToEdit', name);
         this.openModal(FORM_RENAME, { name, id, group });
      },
      createList() {
         this.$refs.select.toggleOptions();
         this.openModal(FORM_CREATE_LIST);
      },
      removeItem(value) {
         //? not used
         this.options.splice(value, 1);
      },

      handleOpen() {
         this.isBackDropOpen = true;
      },

      handleClose() {
         this.isBackDropOpen = false;
      },
      
   },

   computed: {
      currentCategoryName() {
         let name = '';

         if (this.$store.getters['app/getCurrentCategory'].name) {
            name = this.$store.getters['app/getCurrentCategory'].name;
         } else if (this.categories[0]?.name) {
            name = this.categories[0]?.name;
         }
         return name;
      },
      categoryListHasChanges() {
         //!crutch for update category list when add or remove list item
         return this.$store.getters['app/getCategoryListHaschanges'];
      },

      builtInCategories() {
         return this.categories.filter(el => el.group != 'list');
      },
      predefinedLists() {
         const predefinedLists = this.categories
            .filter(el => PREDEFINED_CATEGORIES.includes(el.name))
            .map(el => {
               return {
                  ...el,
                  actions: [
                     {
                        callback: () => null,
                        icon: null,
                     },
                     {
                        callback: () => null,
                        icon: null,
                     },
                  ],
               };
            });

         return predefinedLists;
      },
      userLists() {
         const userLists = this.categories
            .filter(el => el.group === 'list' && PREDEFINED_CATEGORIES.includes(el.name) === false)
            .map(el => {
               return {
                  ...el,
                  actions: [
                     {
                        callback: () => this.editItem(el.name, el._id, el.group),
                        icon: IconEdit,
                     },
                     {
                        callback: () => this.deleteItem(el.name, el._id),
                        icon: IconDelete,
                     },
                  ],
               };
            });

         return userLists;
      },

      /*     selected() {
         return this.$store.getters['advice/getSelectedCategory']
            ? this.$store.getters['advice/getSelectedCategory']
            : '';
      }, */

      /*      categories() {
         return [
            ...this.$store.getters['advice/getCategoriesList'].map(category => {
               return { value: category._id, name: category.code };
            }),
            { name: '', value: '12333321' },
         ];
      }, */

      lists() {
         let res = [];
         /*          this.$store.getters['advice/getLists'].map(list => {
            if (list.name != '') {
               res.push({
                  ...list,
                  actions: [
                     {
                        callback: list.name.toLowerCase() !== 'view later' ? () => this.editItem(list.name) : null,

                        icon: list.name.toLowerCase() !== 'view later' ? IconEdit : null,
                     },
                     {
                        callback: list.name.toLowerCase() !== 'view later' ? () => this.deleteItem(list.name) : null,
                        icon: list.name.toLowerCase() !== 'view later' ? IconDelete : null,
                     },
                  ],
               });
            }
         }); */
         return res;
      },

      viewLater() {
         let res = [];
         /*          this.$store.getters['advice/getLists'].map(list => {
            if (list.name === 'View Later') {
               res.push(list);
            }
         }); */
         return res;
      },
   },

   watch: {
      async categoryListHasChanges() {
         //!crutch for update category list when add or remove list item
         const categories = await this.$http.get('/resources/categories');
         this.categories = categories.data.filter(el => EXCLUDED_CATEGORIES.includes(el.name) === false);
         this.$store.commit(`app/UPDATE_CUSTOM_LISTS`, this.categories.filter(el => el.group === 'list'));
      },

      isBackDropOpen() {
         this.$store.commit('advice/updateIsCategoriesListOpen', this.isBackDropOpen);
      },
   },
};
</script>

<style>
.backdrop {
   background: rgba(0, 0, 0, 0.5);
   position: fixed;
   width: 100%;
   height: 100%;
   top: 66px;
   left: 0;
   z-index: 201;
}
.img_create {
   width: 16.7px;
   height: 16px;
   margin-right: 10px;
}
.content_btnCreate {
   display: flex;
   align-items: center;
   font-weight: 400;
   /* padding-top: 10px;
   padding-bottom: 10px; */
   padding: 10px;
   margin-top: 20px;

   margin-left: 18px;
   font-size: 16px;
   color: var(--shape-hover);
}
.buttonCreate {
   margin-bottom: 20px;
   transform: translateY(-12px);
}
/* .create_text {
   padding: 12px;
} */
/* .create_text:hover {
   background: #e7f2ff;
   border-radius: 10px;
} */
.content_btnCreate:hover {
   background: #e7f2ff;
   border-radius: 10px;
}
@media (min-width: 801px) {
   .backdrop {
      display: none;
   }
}
</style>
