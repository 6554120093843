<template>
   <div class="modal__content__box">
      <div class="modal__content">
         <div class="item__modal">
            <Typography weigth="weigth" :size="'bigger'">Rename list</Typography>
         </div>
         <form action="" @submit.prevent="submitHandler">
            <div class="input__prising_modal">
               <SWInput
                  :class="{ invalidDataErrorStyles: showErrorMessage }"
                  :errorMessage="'The name field cannot be empty'"
                  :isError="showErrorMessage"
                  placeholder="My new list"
                  v-model="valueInput"
               />
            </div>
            <div class="button_check__modal">
               <SWButton variant="primary" type="submit" size="big">Save</SWButton>
            </div>
            <div class="button_skip__modal">
               <SWButton :isCurrentClass="true" variant="secondary" size="big" @click="$emit('onCancel')"
                  >Cancel</SWButton
               >
            </div>
         </form>
      </div>
   </div>
</template>

<script>
import { SWButton, SWInput } from 'sw-ui-kit';
import Typography from '../../../components/UI/Typography.vue';
export default {
   inject: ['snackBar'],
   components: {
      Typography,
      SWButton,
      SWInput,
   },
   props: {
      formData: {
         type: Object,
         required: false,
      },
   },
   data() {
      return {
         isOpen: false,
         valueInput: '',
         showErrorMessage: false,
      };
   },

   mounted() {
      this.valueInput = this.formData?.name;
      if (sessionStorage.getItem('isShowImprovePrompt')) {
         try {
            this.isOpen = JSON.parse(sessionStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      }

      if (this.categoryToEdit?.list_name || this.selectedList?.name) {
         this.valueInput = this.isCategoriesListOpen ? this.categoryToEdit.list_name : this.selectedList?.name;
      }
   },

   methods: {
      onSkip() {
         sessionStorage.setItem('isShowImprovePrompt', false);
         this.isOpen = false;
      },
      submitHandler() {
         if (this.valueInput !== '') {
            this.register(this.valueInput);
            this.snackBar('Your list has been renamed to ' + this.valueInput);
            //this.$store.commit('app/UPDATE_CATEGORY_LIST_HAS_CHANGES');

            this.$emit('onCancel');
         } else {
            this.showErrorMessage = true;
            const setShowError = setTimeout(() => {
               this.showErrorMessage = false;
               clearTimeout(setShowError);
            }, 2000);
         }
      },
      async register(data) {
         //if (!this.selectedList && !this.categoryToEdit) return;
         try {

            await this.$http.put('resources/update/lists', {
               name: data,
               _id: this.formData.id,
            });
            //this.$store.dispatch(`advice/${actions.FETCH}`);
            //this.$store.commit(`advice/${mutations.UPDATE_SELECTED_CATEGORY}`, data);
            //this.$store.commit('app/UPDATE_CURRENT_CATEGORY', { id: this.formData.id, name: data, group: this.formData?.group });
            this.$store.commit(`app/UPDATE_CURRENT_CATEGORY`, { id: this.formData.id, name: data, group: this.formData?.group });
            this.$store.commit('app/UPDATE_CATEGORY_LIST_HAS_CHANGES');
            
            this.$emit('onCancel');
         } catch (err) {
            this.err = err;
         }
      },
   },
   computed: {
      isCategoriesListOpen() {
         return this.$store.getters['advice/getIsCategoriesListOpen'];
      },

      categoryToEdit() {
         return this.$store.getters['advice/getCategoryToEdit'];
      },
      selectedList() {
         return this.$store.getters['advice/getListsSelecting'].find(
            el => el.name === this.$store.getters['advice/getSelectedCategory'],
         );
      },
      isEditMode() {
         return this.$store.getters['advice/getEditMode'];
      },
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   flex-direction: column;
   color: var(--font-color-black);
}
.modal__content {
   display: flex;
   flex-direction: column;
   width: 340px;
}

.input__prising_modal {
   margin-bottom: 40px;
}
.modal__contentModal {
   max-width: 358px;
}
.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}

.item__modal {
   margin-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}
</style>
