<template>
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_1383_19162)">
         <path d="M4 7H20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M10 11V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M14 11V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
         <path
            d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </g>
      <defs>
         <clipPath id="clip0_1383_19162">
            <rect width="24" height="24" fill="white" />
         </clipPath>
      </defs>
   </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
   stroke: var(--default-color-svg);
}
</style>
